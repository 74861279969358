/// <reference types="@types/google.maps" />

declare global {
    interface Window {
        initMap: () => Promise<void>;
        loadSegments: (url: string) => void;
    }
}

let map: google.maps.Map;

export async function initMap(lat?: number, lng?: number, zoom?: number): Promise<void> {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    if (lat === undefined || lng === undefined) {
        // Approximately Barcelona
        lat = 41.5911589;
        lng = 1.5208624;
    }
    if (zoom === undefined) {
        zoom = 9;
    }
    map = new Map(document.getElementById('map') as HTMLElement, {
        center: {lat: lat, lng: lng},
        zoom: zoom
    });
}

export function loadSegments(url: string): void {
    fetch(url)
        .then(function(response) {
            return response.json();
        })
        .then(function(result) {
            const points = result.features.filter((item: any) => item.geometry.type === "Point");
            const lines = result.features.filter((item: any) => item.geometry.type === "LineString");
            
            lines.forEach((feature: any) => {
                const path = new google.maps.Polyline({
                    path: feature.geometry.coordinates.map((coord: number[]) => ({
                        lat: coord[1],
                        lng: coord[0]
                    })),
                    geodesic: true,
                    strokeColor: feature.properties.color || '#000000', // Use the color from the API or default to black
                    strokeOpacity: 1.0,
                    strokeWeight: 2
                });
                path.setMap(map);
            });
/*
            points.forEach((point: any) => {
                const marker = new google.maps.Marker({
                    position: {
                        lat: point.geometry.coordinates[1],
                        lng: point.geometry.coordinates[0]
                    },
                    map: map,
                    title: point.properties.name
                });

                const infowindow = new google.maps.InfoWindow({
                    content: point.properties.name
                });

                marker.addListener('click', () => {
                    infowindow.open(map, marker);
                });
            });
*/
        });
}

window.initMap = initMap;
window.loadSegments = loadSegments;